import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Typography, Container } from "@material-ui/core"

const EstimateSuccessPage = props => {
  return (
    <Layout titleId="estimate-success-title" descId="estimate-success-desc">
      <SEO title="Estimate request sent" />
      <Container maxWidth="sm">
        <Typography id="estimate-success-title" variant="h2" gutterBottom>
          Thanks for your request
        </Typography>

        <Typography id="estimate-success-desc" color="secondary" variant="overline">
          You can expect an answer within 1 to 2 business days.
        </Typography>
      </Container>
    </Layout>
  )
}

export default EstimateSuccessPage
